// src/components/Hero.jsx
import React from 'react';
import { motion } from 'framer-motion';

const Hero = () => {
  return (
    <motion.section
      className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white text-center py-20"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h1 className="text-5xl font-bold mb-4">Compressed Zip</h1>
      <p className="text-xl">Creating powerful and efficient applications, from APIs and backends to data processing and websites.</p>
    </motion.section>
  );
};

export default Hero;
